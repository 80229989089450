import React from 'react';
import { fade, makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { PRIMARY } from 'constant/colors';
import Footer from './Footer/index';
import IS_AUTHENTICATED from '../../constant/authentication';

const useStyles = makeStyles(theme => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  tab: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
    display: 'flex',
  },
  footerWrapper: {
    width: '100%',
    height: '100px',
    marginTop: '40px',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create('width'),
    width: '100%',
  },
  sectionDesktop: {
    display: 'flex',
  },
  footerItem: {
    width: '100%',
    height: '100px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    flexGrow: 1,
    cursor: 'pointer',
  },
  bottomNavigation: {
    position: 'fixed',
    bottom: 0,
    width: '100%',
    backgroundColor: PRIMARY,
    zIndex: 1,
  },
}));

export default function Navigation(WrappedComponent) {
  const classes = useStyles();
  return class extends React.Component {
    componentDidUpdate() {}

    render() {
      const { history } = this.props;
      return (
        <>
          <div style={{ paddingTop: '60px' }}>
            <AppBar position="fixed">
              <Toolbar>
                <Typography
                  variant="h6"
                  className={classes.title}
                  onClick={() => {
                    history.push('/');
                  }}
                >
                  Voodoocloth
                </Typography>
                <div className={classes.sectionDesktop}>
                  <IconButton
                    name="account_button"
                    edge="end"
                    onClick={() => {
                      let href = '';
                      if (localStorage.getItem(IS_AUTHENTICATED)) {
                        href = '/akun';
                      } else {
                        href = '/login';
                      }
                      window.location.href = href;
                    }}
                    color="inherit"
                  >
                    <AccountCircle />
                  </IconButton>
                </div>
              </Toolbar>
            </AppBar>
            <Box
              pt={5}
              style={{
                height: 'auto',
                background: '#fff',
              }}
            >
              <WrappedComponent {...this.props} />
            </Box>
          </div>
          <Footer />
        </>
      );
    }
  };
}
