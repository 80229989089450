import React, { Suspense, lazy } from 'react';
import { Route, Switch, BrowserRouter } from 'react-router-dom';
import { createMuiTheme, ThemeProvider, responsiveFontSizes } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import PrivateRoute from './PrivateRoute';
import { PRIMARY } from './constant/colors';
import Navigation from './container/hoc/Navigation';

const Home = lazy(() => import('./container/Home/index'));
const Terms = lazy(() => import('./container/Terms/index'));
const Privacy = lazy(() => import('./container/Privacy/index'));
const AboutUs = lazy(() => import('./container/AboutUs/index'));
const ContactUs = lazy(() => import('./container/ContactUs/index'));
const ImageSPKProof = lazy(() => import('./container/Spk/Proof/index'));
const Wallet = lazy(() => import('./container/Wallet/index'));
const Account = lazy(() => import('./container/Account/index'));
const ResetPassword = lazy(() => import('./container/Account/ResetPassword/index'));
const AuthLogin = lazy(() => import('./container/Authentication/RevampAuth/Login'));
const AuthRegister = lazy(() => import('./container/Authentication/RevampAuth/Register'));
const AuthForgetPassword = lazy(() =>
  import('./container/Authentication/RevampAuth/ForgetPassword'),
);
const AuthResetPassword = lazy(() => import('./container/Authentication/RevampAuth/ResetPassword'));
const Cart = lazy(() => import('./container/Cart/index'));
const Explore = lazy(() => import('./container/Explore/index'));
const ExploreDetail = lazy(() => import('./container/Explore/Detail/index'));
const RegisterSuccess = lazy(() => import('./container/Authentication/Register/Success/index'));
const Mitra = lazy(() => import('./container/Mitra/index'));
const OrderStatus = lazy(() => import('./container/Order/Status/index'));
const Promo = lazy(() => import('./container/Promo/index'));
const ProductDetail = lazy(() => import('./container/Product/Detail/index'));
const ProductWebsiteDetail = lazy(() => import('./container/Product/DetailWebsite/index'));
const ProductCategory = lazy(() => import('./container/Product/Category/index'));
const ProductCategoryWebsite = lazy(() => import('./container/Product/Category/Website/index'));
const ExploreOrderCheckout = lazy(() => import('./container/Explore/Order/Checkout/index'));
const OrderProductInternalCheckout = lazy(() =>
  import('./container/Order/CheckoutProductInternal/index'),
);
const OrderCheckoutWebsite = lazy(() => import('./container/Order/CheckoutWebsite/index'));
const OrderSuccess = lazy(() => import('./container/Order/Success'));
const OrderWebsiteSuccess = lazy(() => import('./container/Order/Success/Website/index'));
const VerifyEmail = lazy(() => import('./container/Authentication/VerifyEmail/index'));
const Search = lazy(() => import('./container/Search/index'));

// new Screen
const NewListProudcts = lazy(() => import('./container/NewScreen/ListProduct'));
/* const NewHome = lazy(() => import('./container/NewScreen/Home')); */

let theme = createMuiTheme({
  typography: {
    fontFamily: ['Poppins', 'Montserrat', 'Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(','),
  },
  palette: {
    primary: {
      main: PRIMARY,
    },
    background: {
      default: '#fff',
    },
  },
});
theme = responsiveFontSizes(theme);

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;
    if (hasError) {
      return <p>Loading failed! Please reload.</p>;
    }

    return children;
  }
}

function Routes() {
  return (
    <BrowserRouter>
      <Switch>
        <ErrorBoundary>
          <Suspense
            fallback={() => {
              return <div>Sedang memuat...</div>;
            }}
          >
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <Route exact path="/" component={Navigation(Home)} />
              <PrivateRoute exact path="/akun" component={Navigation(Account)} />
              <Route exact path="/atur-ulang-sandi" component={Navigation(ResetPassword)} />
              <Route exact path="/beranda" component={Navigation(Home)} />
              <Route path="/cari" component={Navigation(Search)} />
              <Route exact path="/daftar/berhasil" component={Navigation(RegisterSuccess)} />
              <Route exact path="/keranjang" component={Navigation(Cart)} />
              <Route exact path="/mitra" component={Navigation(Mitra)} />
              <Route exact path="/jelajahi" component={Navigation(Explore)} />
              <Route exact path="/dompet" component={Navigation(Wallet)} />
              <Route path="/ambil-pesanan/checkout" component={Navigation(ExploreOrderCheckout)} />
              <Route exact path="/jelajahi/detail/:id" component={Navigation(ExploreDetail)} />
              <Route exact path="/login" component={AuthLogin} />
              <Route exact path="/register" component={AuthRegister} />
              <Route exact path="/lupa-password" component={AuthForgetPassword} />
              <Route exact path="/reset-sandi" component={AuthResetPassword} />
              <Route exact path="/order-status" component={OrderStatus} />
              <Route exact path="/pembayaran-menunggu" component={Navigation(Account)} />
              <Route exact path="/pembayaran-terima-kasih" component={Navigation(Account)} />
              <Route exact path="/penawaran-design" component={Navigation(Account)} />
              <Route exact path="/penawaran-screen-printing" component={Navigation(Account)} />
              <Route exact path="/promo" component={Navigation(Promo)} />
              <Route path="/produk/kategori" component={Navigation(ProductCategory)} />
              <Route path="/p/kategori" component={Navigation(ProductCategoryWebsite)} />
              <Route path="/produk/detail" component={Navigation(ProductDetail)} />
              <Route path="/p/detail" component={Navigation(ProductWebsiteDetail)} />
              <PrivateRoute
                path="/pesan/checkout"
                component={Navigation(OrderProductInternalCheckout)}
              />
              <PrivateRoute path="/checkout" component={Navigation(OrderCheckoutWebsite)} />
              <PrivateRoute path="/pesan/berhasil" component={Navigation(OrderSuccess)} />
              <PrivateRoute
                exact
                path="/order/berhasil"
                component={Navigation(OrderWebsiteSuccess)}
              />
              <Route exact path="/verify-email" component={Navigation(VerifyEmail)} />
              <Route exact path="/terms" component={Terms} />
              <Route exact path="/privacy" component={Privacy} />
              <Route exact path="/about-us" component={AboutUs} />
              <Route exact path="/contact-us" component={ContactUs} />
              <Route exact path="/bukti-spk" component={ImageSPKProof} />
              <Route exact path="/test-page" component={ImageSPKProof} />

              {/* new Screen> */}
              <Route path="/home/old" component={Navigation(Home)} />
              <Route path="/new-screens/products" component={Navigation(NewListProudcts)} />
            </ThemeProvider>
          </Suspense>
        </ErrorBoundary>
      </Switch>
    </BrowserRouter>
  );
}
export default Routes;
