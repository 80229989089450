import React from 'react';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import HomeIcon from '@material-ui/icons/Home';
import SearchIcon from '@material-ui/icons/Search';
import PeopleIcon from '@material-ui/icons/People';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useHistory } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import YouTubeIcon from '@material-ui/icons/YouTube';
import IconButton from '@material-ui/core/IconButton';
import { PRIMARY } from 'constant/colors';

const useStyles = makeStyles(theme => ({
  tab: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  bottomNavigation: {
    position: 'fixed',
    bottom: 0,
    width: '100%',
    backgroundColor: PRIMARY,
    zIndex: 1,
  },
  footerWrapper: {
    bottom: 0,
    width: '100%',
    marginTop: theme.spacing(4),
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    paddingBottom: theme.spacing(6),
    [theme.breakpoints.down('sm')]: {
      paddingBottom: theme.spacing(9),
    },
  },

  copyright: {
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(1),
    },
  },
}));

const bottomTabOptions = [
  {
    index: 0,
    label: 'Home',
    link: '/',
  },
  {
    index: 1,
    label: 'Search',
    link: '/cari',
  },
  {
    index: 2,
    label: 'Akun',
    link: '/akun',
  },
];

export default function Footer() {
  const classes = useStyles();
  const matches = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const history = useHistory();

  return (
    <>
      <Paper elevation={3} className={classes.footerWrapper}>
        <Grid container direction="row">
          <Grid item xs={6} sm={2}>
            <Typography variant="subtitle2" gutterBottom>
              Voodoocloth
            </Typography>
            <Typography variant="body2">
              <Link
                color="textSecondary"
                onClick={() => {
                  history.push('/about-us');
                }}
              >
                Tentang Voodoocloth
              </Link>
            </Typography>
          </Grid>
          <Grid item xs={6} sm={2}>
            <Typography variant="subtitle2" gutterBottom>
              Bantuan dan Panduan
            </Typography>
            <Typography variant="body2" gutterBottom>
              <Link
                color="textSecondary"
                onClick={() => {
                  history.push('/terms');
                }}
              >
                Syarat dan Ketentuan
              </Link>
            </Typography>

            <Typography variant="body2" gutterBottom>
              <Link
                onClick={() => {
                  history.push('/privacy');
                }}
                color="textSecondary"
              >
                Kebijakan Privasi
              </Link>
            </Typography>
            <Typography variant="body2" gutterBottom>
              <Link
                onClick={() => {
                  history.push('/contact-us');
                }}
                color="textSecondary"
              >
                Hubungi Kami
              </Link>
            </Typography>
          </Grid>
          <Grid item xs={12} sm={8} container justify="center">
            <Box>
              <Typography color="textSecondary" variant="subtitle2" className={classes.copyright}>
                © 2019 - 2024, PT. Multi Grafika Indonesia.
              </Typography>
              <IconButton
                name="facebook_icon"
                onClick={() => {
                  window.location.href = 'https://web.facebook.com/profile.php?id=100004665393046';
                }}
              >
                <FacebookIcon />
              </IconButton>
              <IconButton
                name="instagram_icon"
                onClick={() => {
                  window.location.href = 'https://www.instagram.com/voodoo_cloth/';
                }}
              >
                <InstagramIcon />
              </IconButton>
              <IconButton
                name="youtube_icon"
                onClick={() => {
                  window.location.href =
                    'https://www.youtube.com/channel/UCuZLBMAjwlhfNbE4adi-V6A/videos';
                }}
              >
                <YouTubeIcon />
              </IconButton>
            </Box>
          </Grid>
        </Grid>
      </Paper>
      {matches && (
        <BottomNavigation
          className={classes.bottomNavigation}
          onChange={(event, newValue) => {
            // eslint-disable-next-line array-callback-return
            bottomTabOptions.map(value => {
              const { link, index } = value;
              if (index === newValue) {
                history.push(link);
              }
            });
          }}
          showLabels
        >
          <BottomNavigationAction icon={<HomeIcon style={{ color: 'white' }} />} />
          <BottomNavigationAction icon={<SearchIcon style={{ color: 'white' }} />} />
          <BottomNavigationAction icon={<PeopleIcon style={{ color: 'white' }} />} />
        </BottomNavigation>
      )}
    </>
  );
}
