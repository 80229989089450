export const statement = `
mutation createRefreshToken($refresh_token:String!){
  createRefreshToken(refresh_token: $refresh_token){
   accountRole {
      role
    }
    isEmailConfirmed
    accessToken
    refreshToken
  }
}
`;

export default statement;
