/* eslint-disable max-len */
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { createHttpLink } from 'apollo-link-http';
import { createPersistedQueryLink } from 'apollo-link-persisted-queries';
import { onError } from 'apollo-link-error';
import { ApolloLink, from } from 'apollo-link';
import promiseToObservable from './promiseToObservable';
import REFRESH_TOKEN from './graphql/Account/Mutation/refreshToken';
import IS_AUTHENTICATED from './constant/authentication';

const httpLink = createPersistedQueryLink({ useGETForHashedQueries: true }).concat(
  createHttpLink({
    uri: process.env.REACT_APP_GRAPH_QL_URL,
  }),
);

const authLink = new ApolloLink((operation, forward) => {
  let token = null;
  if (localStorage.getItem(IS_AUTHENTICATED)) {
    token = localStorage.getItem(IS_AUTHENTICATED);
  }
  const tokenAsObject = JSON.parse(token);
  operation.setContext({
    headers: {
      Authorization: token ? `Bearer ${tokenAsObject.accessToken}` : '',
    },
  });
  return forward(operation);
});

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        productWebsiteVariant(_, { args, toReference }) {
          const { id } = args;
          return toReference({
            __typename: 'ProductWebsiteVariant',
            id,
          });
        },
        productWebsite(_, { args, toReference }) {
          const { id } = args;
          return toReference({
            __typename: 'ProductWebsite',
            id,
          });
        },
        product(_, { args, toReference }) {
          const { id } = args;
          return toReference({
            __typename: 'nodeQueryProduct',
            id,
          });
        },
      },
    },
  },
});

// eslint-disable-next-line consistent-return
const getRefreshToken = async () => {
  let token = null;
  if (localStorage.getItem(IS_AUTHENTICATED)) {
    token = localStorage.getItem(IS_AUTHENTICATED);
  }
  const tokenAsObject = JSON.parse(token);
  const optionLogin = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      query: REFRESH_TOKEN,
      variables: {
        refresh_token: tokenAsObject.refreshToken,
      },
    }),
  };
  const refreshTokenResponse = await fetch(process.env.REACT_APP_GRAPH_QL_URL, optionLogin);
  const refreshTokenResponseResult = await refreshTokenResponse.json();
  if (refreshTokenResponseResult.errors.length > 0) {
    window.location.href = '/';
    localStorage.removeItem(IS_AUTHENTICATED);
  } else {
    return refreshTokenResponseResult.data.createRefreshToken;
  }
};

// eslint-disable-next-line consistent-return
const errorHandle = onError(({ graphQLErrors, operation, forward }) => {
  if (graphQLErrors[0].message === 'Unauthorized') {
    return promiseToObservable(getRefreshToken()).flatMap(value => {
      const oldHeaders = operation.getContext().headers;
      if (value !== null) {
        const forMerge = {
          Authorization: `Bearer ${value.accessToken}`,
        };
        const newHeader = {
          ...oldHeaders,
          ...forMerge,
        };
        localStorage.setItem(IS_AUTHENTICATED, JSON.stringify(value));
        operation.setContext({
          headers: {
            ...newHeader,
          },
        });
      }
      return forward(operation);
    });
  }
});
const link = from([authLink, errorHandle, httpLink]);

const client = new ApolloClient({
  link,
  cache,
  /* name: 'web',
  version: '1.0', */
});

export default client;
