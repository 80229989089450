import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import Snackbar from '@material-ui/core/Snackbar';
import { ApolloProvider } from 'react-apollo';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import Routes from './routes';
import AuthContext from './AuthContext';
import client from './client';
import './index.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const WOW = require('wow.js');

const initialState = {
  productInternal: {},
};

Sentry.init({
  dsn: 'https://4d47b4ffa98c43029b386e300bdc13ee@o468796.ingest.sentry.io/5498056',
  integrations: [new Integrations.BrowserTracing()],
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});

const BUY_PRODUCT_INTERNAL = 'BUY_PRODUCT_INTERNAL';

function reducer(state, action) {
  switch (action.type) {
    case BUY_PRODUCT_INTERNAL:
      return {
        productInternal: action.payload,
      };

    default:
      return initialState;
  }
}

const App = () => {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  useEffect(() => {
    if (!WOW) {
      new WOW({
        live: false,
      }).init();
    }
  }, []);
  return (
    <ApolloProvider client={client}>
      <AuthContext.Provider
        value={{
          showError,
          errorMessage,
          setShowError,
          setErrorMessage,
          state,
          dispatch,
          name: 'faris',
        }}
      >
        <Sentry.ErrorBoundary fallback="An error has occured">
          <Snackbar
            autoHideDuration={500}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={showError}
            onClose={() => {
              setShowError(false);
            }}
            message={errorMessage}
          />
          <Routes />
        </Sentry.ErrorBoundary>
      </AuthContext.Provider>
    </ApolloProvider>
  );
};

ReactDOM.render(<App />, document.getElementById('root'));
